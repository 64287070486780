import React from 'react'
import {
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBCardImage,
    MDBRipple,
    MDBListGroup,
    MDBListGroupItem,
    MDBBtn,
    MDBCardFooter
} from 'mdb-react-ui-kit';
import bbsr from '../img/bbsr.jpg'
import hyd from '../img/hyd.jpg'
import blr from '../img/bangalore.jpg'
import kol from '../img/kolkata.jpg'

function Community() {
    return (
        <div className='mt-5 bg-white p-3 mx-3' id='community'>
            <div
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out">
                <h1 style={{ fontSize: "3em", fontWeight: "700", color: "#FF6B6B" }}>Discover Diverse Communities</h1>
                <h5>Connect, belong, and celebrate uniqueness.</h5>
            </div>

            <div className='d-flex flex-wrap mt-4 justify-content-center'>
                <MDBCard style={{ maxWidth: "300px" }} className='my-3 rounded-7 MDCard shadow-5'
                    data-aos="zoom-in-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out">
                    <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay p-2'>
                        <MDBCardImage src={bbsr} fluid alt='...' className='rounded-5 shadow-5' style={{ height: "11em", width: "100%" }} />
                        <a>
                            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                        </a>
                    </MDBRipple>
                    <MDBCardBody>
                        <MDBListGroup flush>
                            <MDBListGroupItem style={{ fontSize: "0.95em" }} className='text-center'>Bhubaneswar Community</MDBListGroupItem>
                        </MDBListGroup>
                        <MDBCardText className='mt-2'>
                        <span className='fw-bolder'>Discover Self in Serenity</span><br />
                            Explore the serene side of fashion and self-expression with our Bhubaneshwar community. Join us in this tranquil space where style meets authenticity, and discover the power of embracing your true self.
                        </MDBCardText>
                    </MDBCardBody>
                    <MDBCardFooter className='mx-auto'><MDBBtn style={{ backgroundColor: "#007BFF" }}>Join Now</MDBBtn></MDBCardFooter>
                </MDBCard>
                &nbsp;&nbsp;
                <MDBCard style={{ maxWidth: "300px" }} className='my-3 rounded-7 MDCard shadow-5'
                    data-aos="zoom-in-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out">
                    <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay p-2'>
                        <MDBCardImage src={blr} fluid alt='...' className='rounded-5 shadow-5' style={{ height: "11em" }} />
                        <a>
                            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                        </a>
                    </MDBRipple>
                    <MDBCardBody>
                        <MDBListGroup flush>
                            <MDBListGroupItem style={{ fontSize: "0.95em" }} className='text-center'>Bangalore Community</MDBListGroupItem>
                        </MDBListGroup>
                        <MDBCardText className='mt-2'>
                            <span className='fw-bolder'>Innovation, Style, and You</span><br />

                            Join our Bangalore community, where innovation and style collide. This dynamic hub celebrates the spirit of creativity, inclusivity, and self-expression, paving the way for a fashion revolution.
                        </MDBCardText>
                    </MDBCardBody>
                    <MDBCardFooter className='mx-auto'><MDBBtn style={{ backgroundColor: "#007BFF" }}>Join Now</MDBBtn></MDBCardFooter>
                </MDBCard>
                &nbsp;&nbsp;
                <MDBCard style={{ maxWidth: "300px" }} className='my-3 rounded-7 MDCard shadow-5'
                    data-aos="zoom-in-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out">
                    <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay p-2'>
                        <MDBCardImage src={hyd} fluid alt='...' className='rounded-5 shadow-5' style={{ height: "11em" }} />
                        <a>
                            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                        </a>
                    </MDBRipple>
                    <MDBCardBody>
                        <MDBListGroup flush>
                            <MDBListGroupItem style={{ fontSize: "0.95em" }} className='text-center'>Hyderabad Community</MDBListGroupItem>
                        </MDBListGroup>
                        <MDBCardText className='mt-2'>
                        <span className='fw-bolder'>Fashion Fit for Royalty</span><br />
                            Our Hyderabad community invites you to experience fashion fit for royalty. Step into a realm where regal elegance meets modern self-authenticity, and where you can express yourself like never before.
                        </MDBCardText>
                    </MDBCardBody>
                    <MDBCardFooter className='mx-auto'><MDBBtn style={{ backgroundColor: "#007BFF" }}>Join Now</MDBBtn></MDBCardFooter>
                </MDBCard>
                &nbsp;&nbsp;
                <MDBCard style={{ maxWidth: "300px" }} className='my-3 rounded-7 MDCard shadow-5'
                    data-aos="zoom-in-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out">
                    <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay p-2'>
                        <MDBCardImage src={kol} fluid alt='...' className='rounded-5 shadow-5'
                            style={{ height: "11em" }} />
                        <a>
                            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                        </a>
                    </MDBRipple>
                    <MDBCardBody>
                        <MDBListGroup flush>
                            <MDBListGroupItem style={{ fontSize: "0.95em" }} className='text-center'>Kolkata Community</MDBListGroupItem>
                        </MDBListGroup>
                        <MDBCardText className='mt-2'>
                        <span className='fw-bolder'>Where Culture Meets Couture</span><br />
                            Kolkata, a city of rich traditions and artistic heritage, is the perfect backdrop for our community. Dive into a world where culture and fashion intertwine, celebrating the beauty of diversity and self-expression.
                        </MDBCardText>
                    </MDBCardBody>
                    <MDBCardFooter className='mx-auto'><MDBBtn style={{ backgroundColor: "#007BFF" }}>Join Now</MDBBtn></MDBCardFooter>
                </MDBCard>
            </div>
        </div>
    )
}

export default Community