import React from 'react'
import img1 from '../img/Memberships.png'
import { TbBarrierBlockOff } from 'react-icons/tb'
import { HiOutlineChatBubbleLeftRight } from 'react-icons/hi2'
import { MdOutlineSupportAgent } from 'react-icons/md'
import { BiMoneyWithdraw } from 'react-icons/bi'
import { GiSellCard } from 'react-icons/gi'
import { CgCommunity } from 'react-icons/cg'
import { IoEarthSharp } from 'react-icons/io5'
import { IoIosPeople } from 'react-icons/io'
import CountUp from 'react-countup'

function Hero() {
    document.body.style.overflowX = "hidden"
    return (
        <div className='hero p-4 d-flex overflow-hidden'>
            <div class="circlebg">
                <div class="circle circle-blue"></div>
                <div class="circle circle-red"></div>
                <div class="circle circle-yellow"></div>
            </div>


            <div class="card1 d-flex rounded-7" >
                <div
                    className='cont'
                    data-aos="fade-right"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                >
                    <h1 className='fw-bolder'><span style={{color:"#4f4f4f", fontSize:"0.8em"}}>WELCOME TO </span><br/><span style={{ fontWeight:"800"}}>INTRUDER COMMUNITY</span></h1>
                    <h1 className='fw-bolder' style={{color:"#FF6B6B", fontSize:"2.3em"}}>EMBRACE YOUR AUTHENTIC SELF</h1>
                    <div className='my-4'>
                        <p className='fw-bold h6'><TbBarrierBlockOff size={25} color='#007BFF' className='' /> Celebrate Self-Expression, Redefine Fashion</p>
                        <p className='fw-bold h6'><HiOutlineChatBubbleLeftRight size={25} color='#007BFF' className='' />Connect Globally, Share Locally</p>
                        <p className='fw-bold h6'><BiMoneyWithdraw size={25} color='#007BFF' className='mr-5' />Unlock Your Potential, Thrive Together</p>
                        <p className='fw-bold h6'><GiSellCard size={25} color='#007BFF' className='mr-5' />Beyond Fashion: Learn, Experience, Grow</p>
                        <p className='fw-bold h6'><MdOutlineSupportAgent size={25} color='#007BFF' className='mr-5' />Connect Seamlessly, Anywhere</p>
                    </div>
                </div>
                <div
                    className='card1-img mt-0'
                    data-aos="fade-left"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                >
                    <img src={img1} alt='bjk' />
                </div>
            </div>

            <div className='mt-4 countCard'>
                <div
                    className='card1 mx-auto d-flex flex-column align-items-center rounded-7'
                    data-aos="zoom-in-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                >
                    <CgCommunity size={70} color='#FF6B6B' />
                    <CountUp end={30} duration={3} suffix='+' style={{ color: "#007BFF", fontSize: "5em" }} className='fw-bold' />
                    <h4 className='text-center' style={{ color: "#007BFF", marginTop: "-0.5em" }}>Communities Built</h4>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div className='card1 mx-auto d-flex flex-column align-items-center rounded-7 mx-3'
                data-aos="zoom-in-up"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out">
                    <IoIosPeople size={56} color='#FF6B6B' />
                    <CountUp end={6000} duration={3} suffix='+' style={{ color: "#007BFF", fontSize: "5em" }} className='fw-bold' />
                    <h4 className='text-center' style={{ color: "#007BFF", marginTop: "-0.5em" }}>Members Joined</h4>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div className='card1 mx-auto d-flex flex-column align-items-center rounded-7'
                    data-aos="zoom-in-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                >
                    <IoEarthSharp size={54} color='#FF6B6B' />
                    <CountUp end={50} duration={3} suffix='+' style={{ color: "#007BFF", fontSize: "5em" }} className='fw-bold' />
                    <h4 className='text-center' style={{ color: "#007BFF", marginTop: "-0.5em" }}>Projects Done</h4>
                </div>   
            </div>
        </div>

    )
}

export default Hero