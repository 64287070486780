import './App.css';
import NavBar from './Components/NavBar';
import Hero from './Components/Hero';
import Community from './Components/Community';
import About from './Components/About';
import Why from './Components/Why';
import Create from './Components/Create';
import FooTer from './Components/FooTer';

function App() {
  document.body.style.overflowX = "hidden";
  return (
    <>
      <NavBar />
      <Hero />
      <Community />
      <About />
      <Why />
      <Create />
      <FooTer />
    </>
  );
}

export default App;
