import React, { useState } from 'react';
import logo from '../img/Intruders.png'
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBCollapse,
} from 'mdb-react-ui-kit';

function NavBar() {
    const [showBasic, setShowBasic] = useState(false);

    return (
        <div className='p-3  sticky-top'>
            <MDBNavbar expand='lg' light bgColor='light' className='p-2 navRound'>
                <MDBContainer fluid>
                    <MDBNavbarBrand href='#'><img src={logo} alt='logo' width={65} /></MDBNavbarBrand>

                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => setShowBasic(!showBasic)}
                    >
                        <MDBIcon icon='bars' fas />
                    </MDBNavbarToggler>

                    <MDBCollapse navbar show={showBasic}>
                        <MDBNavbarNav right fullWidth={false} className='mb-2 mb-lg-0'>
                            <MDBNavbarItem className='navItem mx-2'>
                                <MDBNavbarLink active aria-current='page' className='px-2' href='#'>
                                    Home
                                </MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem className='navItem mx-2'>
                                <MDBNavbarLink active aria-current='page' className='px-2' href='#community'>
                                    Communities
                                </MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem className='navItem mx-2'>
                                <MDBNavbarLink active aria-current='page' className='px-2' href='#about'>
                                    About Us
                                </MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem className='navItem mx-2'>
                                <MDBNavbarLink active aria-current='page' className='px-2' href='#create'>
                                    Create Community
                                </MDBNavbarLink>
                            </MDBNavbarItem>
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBContainer>
            </MDBNavbar>
        </div>
    )
}

export default NavBar