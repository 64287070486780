import React from 'react'
import { MDBInput, MDBBtn } from 'mdb-react-ui-kit';

function Create() {
    function hello() {
        let name = document.getElementsByClassName("name")[0].value;
        let email = document.getElementsByClassName("email")[0].value;
        let num = document.getElementsByClassName("num")[0].value;
        let city = document.getElementsByClassName("city")[0].value;
        let subMail = 'Create a new Community';
        let body = ```Hello Sir/Mam, My name is ${name} and Whatsapp number is ${num}.I want to create a community for ${city} city. Waiting for your response. Thank you!```;
        window.location.href = "mailto:info@intruders.in?subject=" + subMail + "&body=" + body
        // console.log(name, email, num, city);
        document.getElementsByClassName("name")[0].value='';
        document.getElementsByClassName("email")[0].value='';
        document.getElementsByClassName("num")[0].value='';
        document.getElementsByClassName("city")[0].value='';
    }
    return (
        <>
            <div className="mx-4 my-2" id='create'>
                <div className='p-4 mt-5 m-4 rounded-7 create mx-auto' style={{ backgroundColor: "#f4f5f7" }}>
                    <h1 className='fw-bolder' style={{ color: "#FF6B6B", fontSize: "3em" }}>Create your own community</h1>
                    <h6>Be the architect of your safe space. With our platform, you have the power to build and customize your very own community from the ground up. Unleash your passions, expertise, and unique vision to create a welcoming haven for like-minded individuals.</h6>

                    <div className='mt-5'>
                        <MDBInput label='Your Name' id='formControlLg' type='text' size='lg' className='createInp name' type='text' width={20} />
                        <br />
                        <MDBInput label='Name of City' id='formControlLg' type='text' size='lg' className='createInp city' type='text' />
                        <br />
                        <MDBInput label='Whatsapp Number' id='formControlLg' type='text' size='lg' className='createInp num' type='tel' />
                        <br />
                        <MDBInput label='Your E-mail' id='formControlLg' type='text' size='lg' className='createInp email' type='email' />
                        <br />
                        <MDBBtn style={{ backgroundColor: "#007BFF" }} onClick={hello}>Submit</MDBBtn>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Create