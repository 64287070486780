import React from 'react'
import png from '../img/1.png'
import png1 from '../img/2.png'
import png2 from '../img/magic_reach_nasio.webp'
import laptop1 from '../img/Laptop Mockup Instagram Post.png'
import { MDBBtn } from 'mdb-react-ui-kit';
import { MDBSpinner } from 'mdb-react-ui-kit';

function Why() {
    return (
        <>
            <div className='m-4 mt-5 p-3'>
                <h1 className='fw-bolder' style={{ color: "#FF6B6B", fontSize: "3em" }}
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="2000">Why join us?</h1>
                <h5 style={{ color: "grey" }}
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    data-aos-anchor-placement="center-bottom">Unleash your passions, expertise, and unique vision</h5>

                <div className='d-flex mt-1 justify-content-around p-4 pb-0 m-4 rounded about'
                    data-aos="fade-right"
                    data-aos-offset="300"
                    data-aos-duration="2000"
                    data-aos-easing="ease-in-sine">

                    <div className='aboutImg whyImg'>
                        <img src={png} alt='abt' />
                    </div>
                    <div className='aboutCont whyCont'>
                        <h1 className='fw-bolder' style={{ color: "#007BFF" }}>we're not just changing fashion; we're changing the world.</h1>
                        <h5 className=''>
                            By becoming a part of our community, you'll be at the forefront of a movement that defies labels, celebrates individuality, and fosters a culture of authenticity. </h5>
                    </div>
                </div>

                <div className='d-flex mt-n4 justify-content-around m-4 rounded about whyCont2'
                    data-aos="fade-left"
                    data-aos-offset="300"
                    data-aos-duration="2000"
                    data-aos-easing="ease-in-sine">
                    <div className='aboutCont'>
                        <h1 className='fw-bolder' style={{ color: "#007BFF" }}>connect with like-minded rebels</h1>
                        <h5 className=''>contribute to a future where everyone can thrive, regardless of gender. It's not just about clothing; it's about empowerment, self-expression, and building a world united in diversity.</h5>
                    </div>
                    <div className='aboutImg whyImg'>
                        <img src={png1} alt='abt' height={400} />
                    </div>
                </div>

                <div className='d-flex mt-n4 justify-content-around p-4 pb-0 m-4 rounded about'
                    data-aos="fade-right"
                    data-aos-offset="300"
                    data-aos-duration="2000"
                    data-aos-easing="ease-in-sine">

                    <div className='aboutImg whyImg'>
                        <img src={png2} alt='abt' />
                    </div>
                    <div className='aboutCont whyCont'>
                        <h1 className='fw-bolder' style={{ color: "#007BFF" }}>Discover Self-Authenticity</h1>
                        <h5 className=''>NUDE empowers you to express your true self confidently</h5>
                    </div>
                </div>
            </div>

            <div className='text-center laptop mb-3'
                data-aos="zoom-in">
                <img src={laptop1} alt='laptop' className='lapImg' />
                <div>
                    <MDBBtn className='rounded-5 me-2' disabled size='lg' style={{ backgroundColor: "#007BFF" }}>
                        Online Store Coming Soon&nbsp;&nbsp;
                        <MDBSpinner color='light' size='sm'>
                        </MDBSpinner>
                    </MDBBtn>
                </div>
            </div>

        </>
    )
}

export default Why