import React from 'react'
import about from '../img/about.png'

function About() {
  return (
    <div className='d-flex mt-4 justify-content-around p-4 pb-0 m-4 rounded-7 shadow-5 about' style={{ backgroundColor: "#fffcf0" }}>
      <div className='aboutCont'
        data-aos="zoom-in-right"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        id='about'>
        <h1 className='fw-bolder' style={{ color: "#007BFF" }}>Who are we?</h1>
        <h5 className='mt-4'>"Who Are We? At Intruders, we are the architects of a world united, without labels. Our vision is simple yet revolutionary: to dismantle the constraints of gender stereotypes and labels that have long confined self-expression. We stand as a beacon of change, breaking barriers and embracing diversity. Our mission is to liberate a society where every individual can be their authentic self without hesitation. Our fashion transcends the boundaries of traditional gender expectations, empowering you to express your true identity without restrictions. With a rich tapestry of inclusive and diverse offerings, we inspire a world where gender is not a limitation but a vibrant tapestry of individuality. Together, let's co-create a future where everyone thrives, unburdened by the constraints of gender."</h5>
      </div>
      <div className='aboutImg'
        data-aos="zoom-in-left"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out">
        <img src={about} alt='abt' />
      </div>

    </div>
  )
}

export default About